import { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import Icon1 from "./assets/images/icon-1.png";
import Icon2 from "./assets/images/icon-2.png";
import Icon3 from "./assets/images/icon-3.png";
import Icon4 from "./assets/images/icon-4.png";
import GifCapi from "./assets/images/gif-capi.gif";
import GifAbout from "./assets/images/gif-about.gif";
import GifMore from "./assets/images/gif-more.gif";
import ImgOrigin from "./assets/images/img-origin.png";
import ImgToken from "./assets/images/img-token.png";
import ImgMore1 from "./assets/images/img-more-1.png";
import ImgMore2 from "./assets/images/img-more-2.png";
import ImgMore3 from "./assets/images/img-more-3.png";
import ImgMore4 from "./assets/images/img-more-4.png";
import ImgMore5 from "./assets/images/img-more-5.png";
import ImgMore6 from "./assets/images/img-more-6.png";
import ImgMore7 from "./assets/images/img-more-7.png";
import ImgMore8 from "./assets/images/img-more-8.png";
import ImgMore9 from "./assets/images/img-more-9.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="page-app">
      <main className="page-main">
        <section data-aos="fade-up" className="section-intro">
          <div className="container wrapper">
            <ul className="list-social">
              <li>
                <a
                  href="https://x.com/Gort_SOL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Icon1} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/Gort_SOL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Icon2} alt="" />
                </a>
              </li>
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={Icon3} alt="" />
                </a>
              </li>
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={Icon4} alt="" />
                </a>
              </li>
            </ul>
            <div className="title">
              <img src={GifCapi} alt="" />
              Meet $GORT
              <img src={GifCapi} alt="" />
            </div>
            <div className="img-intro">
              <img src={ImgOrigin} alt="" />
            </div>
            <div className="content">
              <p className="first-content">
                Hello, I’m Gort! People love me and so do you Gort is made of
                love
              </p>
              <p className="address">CA: TBA</p>
            </div>
            <div className="btn-groups">
              <a href="" className="btn-buy">
                Buy Here!
              </a>
              <Link to="/meme-maker" className="btn-create">
                Create your gort
              </Link>
            </div>
          </div>
        </section>
        <section data-aos="fade-up" className="section-about">
          <div className="container wrapper">
            <h3 className="title">About GORT</h3>
            <div className="img-about">
              <img src={GifAbout} alt="" />
            </div>
            <p className="content">
              Meet Gort, the capybara ready to become your favorite! I'm leading
              the charge for the latest and greatest memecoin on the scene. Just
              like my fellow capybaras, I thrive in groups, love to chill by the
              water, and now I'm diving in the crypto universe with all of you.
            </p>
            <Link to="/meme-maker" className="btn-create">
              Create your gort
            </Link>
          </div>
        </section>
        <section data-aos="fade-up" className="section-tokenomics">
          <div className="container wrapper">
            <h3 className="title">Tokenomics</h3>
            <p className="address">CA: TBA</p>
            <ul className="list-token">
              <li>
                <img src={ImgToken} alt="" />
                <div className="content">
                  <p className="title">Total supply</p>
                  <p className="value">1,000,000,000</p>
                </div>
              </li>
              <li>
                <img src={ImgToken} alt="" />
                <div className="content">
                  <p className="title">LB Burnt</p>
                  <p className="value">100%</p>
                </div>
              </li>
              <li>
                <img src={ImgToken} alt="" />
                <div className="content">
                  <p className="title">TAX</p>
                  <p className="value">0%</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section data-aos="fade-up" className="section-more">
          <div className="container wrapper">
            <img className="img-gif-1" src={GifMore} alt="" />
            <img className="img-gif-2" src={GifMore} alt="" />
            <h3 className="title">more GORT, more fun</h3>
            <ul className="list-more">
              <li>
                <img src={ImgMore1} alt="" />
              </li>
              <li>
                <img src={ImgMore2} alt="" />
              </li>
              <li>
                <img src={ImgMore3} alt="" />
              </li>
              <li>
                <img src={ImgMore4} alt="" />
              </li>
              <li>
                <img src={ImgMore5} alt="" />
              </li>
              <li>
                <img src={ImgMore6} alt="" />
              </li>
              <li>
                <img src={ImgMore7} alt="" />
              </li>
              <li>
                <img src={ImgMore8} alt="" />
              </li>
              <li>
                <img src={ImgMore9} alt="" />
              </li>
            </ul>
            <Link to="/meme-maker" className="btn-create">
              Create your gort
            </Link>
          </div>
        </section>
        <footer className="page-footer">
          <div className="container wrapper">
            <ul className="list-social">
              <li>
                <a
                  href="https://x.com/Gort_SOL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Icon1} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/Gort_SOL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Icon2} alt="" />
                </a>
              </li>
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={Icon3} alt="" />
                </a>
              </li>
              <li>
                <a href="" target="_blank" rel="noopener noreferrer">
                  <img src={Icon4} alt="" />
                </a>
              </li>
            </ul>
            <p>©2024 $GORT, All Right Reserved.</p>
          </div>
        </footer>
      </main>
    </div>
  );
}

export default App;
