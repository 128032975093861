import React, { useEffect, useRef, useState } from "react";
import IconReset from "../../assets/images/icon-reset.png";
import IconDownload from "../../assets/images/icon-download.png";
import ImgDefault from "../../assets/images/icon-block.png";

import IconArrowRight from "../../assets/images/icon-arrow-right.png";
import IconArrowLeft from "../../assets/images/icon-arrow-left.png";

import GifCapi from "../../assets/images/gif-capi.gif";
import ImgCapi from "../../assets/images/img-capy-1.png";
import ImgCapi2 from "../../assets/images/img-capy-2.png";
import ImgCapi3 from "../../assets/images/img-capy-3.png";
import ImgCapi4 from "../../assets/images/img-capy-4.png";
import ImgCapi5 from "../../assets/images/img-capy-5.png";
import ImgCapi6 from "../../assets/images/img-capy-6.png";

import ImgBg1 from "../../assets/images/img-bg-capy-1.png";
import ImgBg2 from "../../assets/images/img-bg-capy-2.png";
import ImgBg3 from "../../assets/images/img-bg-capy-3.png";
import ImgBg4 from "../../assets/images/img-bg-capy-4.png";

import ImgHat1 from "../../assets/images/img-hat-1.png";
import ImgHat2 from "../../assets/images/img-hat-2.png";
import ImgHat3 from "../../assets/images/img-hat-3.png";
import ImgHat4 from "../../assets/images/img-hat-4.png";
import ImgHat5 from "../../assets/images/img-hat-5.png";

import ImgFace1 from "../../assets/images/img-face-1.png";
import ImgFace2 from "../../assets/images/img-face-2.png";
import ImgFace3 from "../../assets/images/img-face-3.png";
import ImgFace4 from "../../assets/images/img-face-4.png";
import ImgFace5 from "../../assets/images/img-face-5.png";

import ImgAccess1 from "../../assets/images/img-acces-1.png";
import ImgAccess2 from "../../assets/images/img-acces-2.png";
import ImgAccess3 from "../../assets/images/img-acces-3.png";
import ImgAccess4 from "../../assets/images/img-acces-4.png";
import ImgAccess5 from "../../assets/images/img-acces-5.png";

import "./style.scss";
import { Link } from "react-router-dom";

const LIST_CAPY = [ImgCapi, ImgCapi2, ImgCapi3, ImgCapi4, ImgCapi5, ImgCapi6];
const LIST_HAT = [ImgDefault, ImgHat1, ImgHat2, ImgHat3, ImgHat4, ImgHat5];
const LIST_FACE = [
  ImgDefault,
  ImgFace1,
  ImgFace2,
  ImgFace3,
  ImgFace4,
  ImgFace5,
];
const LIST_ACCESSORY = [
  ImgDefault,
  ImgAccess1,
  ImgAccess2,
  ImgAccess3,
  ImgAccess4,
  ImgAccess5,
];
const LIST_BACKGROUND = [ImgBg1, ImgBg2, ImgBg3, ImgBg4];

function Customizer({
  customization,
  setCustomization,
  currentIndexes,
  setCurrentIndexes,
}) {
  const handleChange = (category, index) => {
    setCustomization((prev) => ({
      ...prev,
      [category]: getList(category)[index],
    }));
    setCurrentIndexes((prev) => ({
      ...prev,
      [category]: index,
    }));
  };

  const handleLeftClick = (category) => {
    setCurrentIndexes((prev) => {
      const newIndex =
        prev[category] > 0 ? prev[category] - 1 : getList(category).length - 1;
      setCustomization((prevCust) => ({
        ...prevCust,
        [category]: getList(category)[newIndex],
      }));
      return { ...prev, [category]: newIndex };
    });
  };

  const handleRightClick = (category) => {
    setCurrentIndexes((prev) => {
      const newIndex =
        prev[category] < getList(category).length - 1 ? prev[category] + 1 : 0;
      setCustomization((prevCust) => ({
        ...prevCust,
        [category]: getList(category)[newIndex],
      }));
      return { ...prev, [category]: newIndex };
    });
  };

  const getList = (category) => {
    switch (category) {
      case "gort":
        return LIST_CAPY;
      case "hat":
        return LIST_HAT;
      case "face":
        return LIST_FACE;
      case "frontAccessory":
        return LIST_ACCESSORY;
      case "background":
        return LIST_BACKGROUND;
      default:
        return [];
    }
  };

  return (
    <div className="customizer">
      {Object.keys(currentIndexes).map((category) => (
        <div className="content" key={category}>
          <h3>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
          <div className="row-wrapper">
            <button
              className="btn-arrow"
              disabled={currentIndexes[category] === 0}
              style={
                currentIndexes[category] === 0
                  ? {
                      opacity: 0.2,
                    }
                  : { background: "#fff" }
              }
              onClick={() => handleLeftClick(category)}
            >
              <img src={IconArrowLeft} alt="" />
            </button>
            <div className="row">
              {getList(category).map((item, index) => (
                <button
                  key={index}
                  className={
                    index === currentIndexes[category] ? "bg-white" : ""
                  }
                  onClick={() => handleChange(category, index)}
                >
                  <img
                    style={
                      !["background", "gort"].includes(category) && index === 0
                        ? { width: 20, height: 20 }
                        : {}
                    }
                    src={item}
                    alt=""
                  />
                </button>
              ))}
            </div>
            <button
              className="btn-arrow"
              style={
                currentIndexes[category] === getList(category).length - 1
                  ? {
                      opacity: 0.2,
                    }
                  : { background: "#fff" }
              }
              disabled={
                currentIndexes[category] === getList(category).length - 1
              }
              onClick={() => handleRightClick(category)}
            >
              <img src={IconArrowRight} alt="" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function MemeMaker() {
  const canvasRef = useRef(null);

  const [customization, setCustomization] = useState({
    gort: ImgCapi,
    hat: ImgDefault,
    face: ImgDefault,
    frontAccessory: ImgDefault,
    background: ImgBg1,
  });
  const [currentIndexes, setCurrentIndexes] = useState({
    gort: 0,
    hat: 0,
    face: 0,
    frontAccessory: 0,
    background: 0,
  });

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  const drawImage = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const images = [
      customization.background,
      customization.gort,
      customization.hat !== ImgDefault && customization.hat,
      customization.face !== ImgDefault && customization.face,
      customization.frontAccessory !== ImgDefault &&
        customization.frontAccessory,
    ];

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    for (const src of images) {
      if (src) {
        try {
          const img = await loadImage(src);
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        } catch (error) {
          console.error("Error loading image:", error);
        }
      }
    }
  };

  const downloadImage = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download = "customized-image.png";
    link.href = canvas.toDataURL("image/png");
    link.click();
  };

  const handleReset = () => {
    setCustomization({
      gort: ImgCapi,
      hat: ImgDefault,
      face: ImgDefault,
      frontAccessory: ImgDefault,
      background: ImgBg1,
    });
    setCurrentIndexes({
      gort: 0,
      hat: 0,
      face: 0,
      frontAccessory: 0,
      background: 0,
    });
  };

  useEffect(() => {
    drawImage();
  }, [customization]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="meme-maker-page">
      <div className="container wrapper">
        <Link to="/" className="home-back">
          Home
        </Link>

        <div className="title">
          <img src={GifCapi} alt="" />
          GORT Meme Maker
          <img src={GifCapi} alt="" />
        </div>
        <div className="main-content">
          <div className="content-wrapper">
            <div className="preview-container">
              <canvas
                ref={canvasRef}
                width={500}
                height={500}
                className="preview-canvas"
              />
            </div>
            <div className="btn-groups">
              <button onClick={handleReset} className="btn-reset">
                <img src={IconReset} alt="" />
                Reset
              </button>
              <button onClick={downloadImage}>
                <img src={IconDownload} alt="" />
                Download
              </button>
            </div>
          </div>
          <Customizer
            customization={customization}
            setCustomization={setCustomization}
            currentIndexes={currentIndexes}
            setCurrentIndexes={setCurrentIndexes}
          />
        </div>
      </div>
    </div>
  );
}
